@media screen and (max-width: 1400px) {
  .top-section {
    margin-top: 0;
  }
  .photo-screen {
    width: 500px;
  }
}
@media screen and (max-height: 800px) {
  .right-column .top-section {
    padding: 0;
  }
  .right-column .top-section .list-content {
    padding: 0 2rem;
  }
}
@media screen and (max-height: 800px) {
  .right-column .top-section {
    padding-top: 0;
    padding-bottom: 0;
  }
  .right-column .top-section h1 {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.5rem;
  }
  .right-column .top-section .list-content {
    padding: 0 2rem;
  }
  .right-column .top-section .list-content ul {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .right-column .photo-screen {
    width: 350px;
  }
  .left-column .form {
    margin-top: -2rem;
  }
}
