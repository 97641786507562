@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "opensans-regular", "Helvetica", "Arial", sans-serif;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 16px;
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
  html {
    font-size: 13px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.learn-icon {
  width: 80px;
  height: 70px;
}
#root .EmbedFrame.bordered {
  border: none;
}

.text-error {
  color: red !important;
}

.input-error {
  border: 1px solid red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.font-size-32 {
  font-size: 2rem;
}
.font-size-14 {
  font-size: 0.875rem;
}

.recommendations .r-mkdwn a{
  text-decoration: underline;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#spinner {
	display: none;
	width: 200px;
  position: absolute;
  left: 50%; 
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; 

}

#loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(75, 75, 75, 0.16)  ; /* Semi-transparent grey background */
  z-index: 998; /* Ensure the overlay is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  display: none; /* Initially hidden */
}

#loadingOverlayFilter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey background */
  z-index: 10; /* Ensure the overlay is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  display: block; /* Initially hidden */
}

.react-datepicker-wrapper .react-datepicker__input-container input{
  width: 100%;
}
.fab-container{
  z-index: 997;
  bottom: 4vh !important;
  right: 4vw !important;
}

#db-circle-chart-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

#db-circle-chart-container .circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  position: absolute;
}

#db-circle-chart-container .circle.large {
  width: 130px;
  height: 130px;
  background-color: #004876;
  font-size: 22px;

}

#db-circle-chart-container .circle.small {
  width: 60px;
  height: 60px;
  background-color: #009383;
  transform: translate(-80%, 60%);
  font-size: 14px;

}

#side-bar-open{
  /* position: relative; */
  animation-name: side-bar-open-k;
  animation-duration: 0.5s;
}

@keyframes side-bar-open-k {
  0%   {bottom:0px; top:0px; right: -234px; height: 100%;}
  100%  { bottom:200px; top:0px; right: 0px;  height: 100%;}
 
}
#side-bar-close{
  /* position: relative; */
  animation-name: side-bar-close-k;
  animation-duration: 0.5s;
}

@keyframes side-bar-close-k {
  0%  { bottom:200px; top:0px; right: 0px;  height: 100%;}
  100%   {bottom:0px; top:0px; right: -500px; height: 100%;}

}
